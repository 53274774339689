@import "primeicons/primeicons.css";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.btn-default {
  color: white;
  background: none;
  border-radius: 3px;
  border: 1px solid hsla(0,0%,100%,.3);
  outline: none;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.btn-default:hover {
  cursor: pointer;
  border: 1px solid hsla(0,0%,100%,.6);
  background-color: hsla(0,0%,100%,.1);
}

.btn-action {
  color: white;
  background: none;
  border-radius: 3px;
  border: 1px solid #f56110;
  outline: none;
  font-size: 16px;
  width: 100%;
  padding: 8px;
  cursor: pointer;
}

.btn-action:hover {
  cursor: pointer;
  border: 1px solid white;
  background-color: #f56110;
}

//Css for mobile screens
@media (min-width: 0px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 481px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1025px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1201px) {
  html {
    font-size: 20px;
  }
}

$primary-background-color: #19191c;
$primary-header-color: #27282c;

$secondary-gray-color: #3D3E42;
$third-gray-color: #A9A9AB;

$logo-1: #B31B06;
$logo-2: #f56110;
$logo-3: #FCA12D;
$logo-4: #FFC556;

$gradient-1: #4B09AD;
$gradient-2: #3D37B9;
$gradient-3: #286DC1;
$gradient-4: #0D5066;

$error-color: #ff5757;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
